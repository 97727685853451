import React from 'react';
import {Box, Paper, Typography, IconButton, Tooltip} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import WarningIcon from '@mui/icons-material/Warning';
import { blue, amber, green, red } from '@mui/material/colors';
import {marked} from "marked";
import {ScheduleSend} from "@mui/icons-material";
import {formatTimeDistance} from "../utils";

export const messageStatusIcon = {
    failed: <WarningIcon fontSize="small" sx={{ color: 'red' }} />,
    waiting: <ScheduleSend fontSize="small" sx={{ color: 'orange' }} />,
    sent: <DoneIcon fontSize="small" sx={{ color: 'text.secondary' }} />,
    delivered: <DoneAllIcon fontSize="small" sx={{ color: 'text.secondary' }} />,
    read: <DoneAllIcon fontSize="small" sx={{ color: blue[500] }} />
};

const MessageBubble = ({ message, handleSuggestedAnswerClick, pendingReview }) => {
    return (
        <Box
            sx={{
                alignSelf: message.senderType === "assistant" ? 'flex-end' : 'flex-start',
                maxWidth: '80%',
            }}
        >
            <Paper
                elevation={2}
                sx={{
                    p: 1,
                    borderRadius: 3,
                    color: 'text.primary',
                    position: 'relative',
                }}
            >
                {/* Sender Information */}
                {message.senderType === "assistant" && (
                    <Typography variant="subtitle2" color="textSecondary">
                        {`${message.author.firstname} ${message.author.lastname}` + (message.type !== "text" ? ` (${message.type})` : "")}
                    </Typography>
                )}

                {/* Message Content */}
                <Typography
                    variant="body1"
                    component="div" // Ensures the HTML is rendered as a block element
                    dangerouslySetInnerHTML={{ __html: marked(message.content) }}
                    sx={{
                        color: message.type !== "text" && message.type !== "template" ? "red" : "inherit",
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Reactions */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 2,
                            bgcolor: 'background.default',
                            px: 1,
                        }}
                    >
                        {message.reaction && (
                            <Typography variant="body" color="textSecondary">
                                {message.reaction}
                            </Typography>
                        )}
                    </Box>

                    {/* Timestamp and Message Status */}
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                        <Typography variant="caption" color="textSecondary" sx={{ mr: 1 }}>
                            {message.status==="failed" ? "Message could not be sent" : message.status==="waiting" ? "24h exceeded. Wait for client confirmation." : formatTimeDistance(new Date(message.timestamp), true)}
                        </Typography>
                        {message.senderType === "assistant" && (
                            <Box component="span">
                                {messageStatusIcon[message.status]}
                            </Box>
                        )}
                    </Box>
                </Box>

                {/* Suggested Answer Buttons */}
                {message.answerSuggestion.completions.length > 0 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: -40,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {message.answerSuggestion.completions.map((completion, index) => (
                            <Tooltip key={index} title={completion.model} arrow placement={"left"}>
                                <IconButton
                                    size="small"
                                    sx={{
                                        color:
                                            pendingReview &&
                                            pendingReview.messageId === message._id &&
                                            pendingReview.model === completion.model
                                                ? amber[600]
                                                : completion.rating === true
                                                    ? green[600]
                                                    : completion.rating === false
                                                        ? red[600]
                                                        : blue[600],
                                    }}
                                    onClick={() => handleSuggestedAnswerClick(message._id, completion)}
                                >
                                    <AutoAwesomeIcon />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default MessageBubble;